(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/**
 * Instantiate MobileNavigation
 * @param  {Node}       wrapper Dom class or dom node
 * @param  {Object}     options Object holding optional parameters
 * @return {undefined}
 */
var MobileNavigation = function () {
  function MobileNavigation(_ref) {
    var burger = _ref.burger,
        links = _ref.links,
        header = _ref.header,
        menu = _ref.menu,
        _ref$options = _ref.options,
        options = _ref$options === undefined ? {} : _ref$options;

    _classCallCheck(this, MobileNavigation);

    this.burgerDOM = document.querySelector(burger);
    this.links = document.querySelectorAll(links);
    this.header = document.querySelector(header);
    this.menuDOM = document.querySelector(menu);

    this._bindEvents();
  }

  _createClass(MobileNavigation, [{
    key: "_bindEvents",
    value: function _bindEvents() {
      var _this = this;

      this.burgerDOM.addEventListener("click", function () {
        return _this._activate();
      });
      this.links.forEach(function (item) {
        return item.addEventListener("click", function () {
          return _this._close();
        });
      });
    }
  }, {
    key: "_activate",
    value: function _activate(event) {
      if (this.burgerDOM.classList.contains("is-active")) {
        this.burgerDOM.classList.remove("is-active");
        this.menuDOM.classList.remove("is-active");
        // if(this.overlayDOM) this.overlayDOM.classList.remove("is-active")
      } else {
        this.burgerDOM.classList.add("is-active");
        this.menuDOM.classList.add("is-active");
        this.header.classList.add("in-move");
        // if(this.overlayDOM) this.overlayDOM.classList.add("is-active")
      }
    }
  }, {
    key: "_close",
    value: function _close(event) {
      this.burgerDOM.classList.remove("is-active");
      this.menuDOM.classList.remove("is-active");
    }
  }]);

  return MobileNavigation;
}();

exports.default = MobileNavigation;

},{}],2:[function(require,module,exports){
'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var Scroller = function () {
   function Scroller(args) {
      _classCallCheck(this, Scroller);

      this.scrollButton = document.querySelectorAll(args.scrollButton);
      this.dataElId = args.dataElId;
      this.bindEvents();
   }

   _createClass(Scroller, [{
      key: 'bindEvents',
      value: function bindEvents() {
         var _this = this;

         this.scrollButton.forEach(function (el) {

            el.addEventListener('click', function () {
               _this.scroll(el);
            });
         });
      }
   }, {
      key: 'scroll',
      value: function scroll(el) {
         var id = el.getAttribute(this.dataElId);
         id = document.querySelector(id);
         console.log(id);
         // id.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start'});

         var offset = 65;
         var bodyRect = document.body.getBoundingClientRect().top;
         var elementRect = id.getBoundingClientRect().top;
         var elementPosition = elementRect - bodyRect;
         var offsetPosition = elementPosition - offset;

         window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
         });

         // setTimeout(() => {
         //    window.scrollBy(0, -40);
         // }, 500);
      }
   }]);

   return Scroller;
}();

module.exports = Scroller;

},{}],3:[function(require,module,exports){
'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/* example
    const toggler = new Toggler({
        toggleButton: '.js-toggle-menu',
        dataElId: 'data-elId',
        activeClass: 'is-active',           //optional (default class: 'is-active')
    });
*/

var Toggler = function () {
  function Toggler(args) {
    _classCallCheck(this, Toggler);

    this.toggleButton = document.querySelectorAll(args.toggleButton);
    this.toggleContent = document.querySelectorAll(args.toggleContent);
    this.dataElId = args.dataElId;
    this.activeClass = args.activeClass ? args.activeClass : 'is-active';
    this.bindEvents();
  }

  _createClass(Toggler, [{
    key: 'bindEvents',
    value: function bindEvents() {
      var _this = this;

      this.toggleButton.forEach(function (el) {

        el.addEventListener('click', function () {
          _this.toggle(el);
          el.classList.add(_this.activeClass);
        });
      });
    }
  }, {
    key: 'toggle',
    value: function toggle(el) {
      var _this2 = this;

      this.toggleButton.forEach(function (el) {
        el.classList.remove(_this2.activeClass);
      });
      this.toggleContent.forEach(function (el) {
        el.classList.remove(_this2.activeClass);
      });
      var id = el.getAttribute(this.dataElId);
      id = document.querySelector(id);
      id.classList.add(this.activeClass);
      id.scrollIntoView({ behavior: 'smooth' });
    }
  }]);

  return Toggler;
}();

module.exports = Toggler;

},{}],4:[function(require,module,exports){
'use strict';

var _mobile = require('./modules/mobile.js');

var _mobile2 = _interopRequireDefault(_mobile);

var _toggle = require('./modules/toggle.js');

var _toggle2 = _interopRequireDefault(_toggle);

var _scrollto = require('./modules/scrollto.js');

var _scrollto2 = _interopRequireDefault(_scrollto);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// lazyload
// var lazyload = new LazyLoad({
//   elements_selector: ".lazy"
// });

ScrollReveal().reveal('.js-reveal', {
   distance: '100px',
   delay: 400,
   duration: 900,
   origin: 'bottom',
   mobile: false
});

// header move
window.onscroll = function () {
   move_header();
};
function move_header() {
   var header = document.querySelector('.site-header');
   var sticky = header.offsetTop;

   if (window.pageYOffset > sticky) {
      header.classList.add('in-move');
   } else {
      header.classList.remove('in-move');
   }
}
// mobile
new _mobile2.default({
   burger: ".js-mobile",
   links: ".js-scroll",
   header: ".site-header",
   menu: ".js-menu"
   // cancel: ".js-menu-cancel"
});

// const toggler = new Toggler({
//   toggleButton: '.js-tab',
//   toggleContent: '.js-tab-content',
//   dataElId: 'data-tab',
//   activeClass: 'is-active',
// });

var scroll = new _scrollto2.default({
   scrollButton: '.js-scroll',
   dataElId: 'data-target'
});

try {
   var teamSwiper = new Swiper('.team-slider', {
      loop: false,
      slidesPerView: 1,
      spaceBetween: 15,
      autoplay: {
         delay: 4500,
         disableOnInteraction: true
      },
      breakpoints: {
         640: {
            slidesPerView: 2
         },
         768: {
            slidesPerView: 3
         },
         1024: {
            slidesPerView: 4
         },
         1400: {
            slidesPerView: 5
         },
         1600: {
            slidesPerView: 5
         }
      },
      navigation: {
         nextEl: '.btn-new-next',
         prevEl: '.btn-new-prev'
      },
      pagination: {
         el: '.team-pagination',
         bulletClass: 'team-bullet',
         bulletActiveClass: 'team-bullet-active',
         clickable: true,
         renderBullet: function renderBullet(index, className) {
            return '<li class="' + className + '"><span class="team-dot"></span></li>';
         }
      }
   });
   var mySwiper = new Swiper('.client-slider', {
      loop: true,
      slidesPerView: 1,
      spaceBetween: 15,
      breakpoints: {
         640: {
            slidesPerView: 2
         },
         768: {
            slidesPerView: 3
         },
         1024: {
            slidesPerView: 4
         }
      },
      pagination: {
         el: '.client-pagination',
         bulletClass: 'client-bullet',
         bulletActiveClass: 'client-bullet-active',
         clickable: true,
         renderBullet: function renderBullet(index, className) {
            return '<li class="' + className + '"><span class="client-dot"></span></li>';
         }
      }
   });
} catch (error) {
   console.error(error);
}

},{"./modules/mobile.js":1,"./modules/scrollto.js":2,"./modules/toggle.js":3}]},{},[4]);
